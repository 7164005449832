import styled from "styled-components";

export const DefaultButton = styled.button`
    background-color: ${(props) => props.theme.darkYellow};
    border-radius: 25px;
    color: white;
    text-transform: uppercase;
    width: 160px;
    min-width: 140px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
    border: none;
    margin-top: 1rem;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-weight: 500;

    :hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.hoverYellow};
    }
`;

export const SubmitButton = styled(DefaultButton)`
    display: block;
    margin-left: auto;
    margin-top: 2rem;
    width: 170px;
    min-width: 160px;
`;

export const ImageContainer = styled.div`
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

export const ContentTitle = styled.p`
    font-size: 26px;
    font-weight: bold;
    color: ${(props) => props.theme.mainColor}
    font-family: 'Poppins', sans-serif;

    @media (max-width: 480px) {
        text-align: center;
        font-size: 26px;
        margin-bottom: 0;
    }
`;
export const CloseButton = styled.p`
    color: ${(props) => props.theme.lighterGray};
    float: right;
    font-size: 32px;
    font-weight: bold;
    cursor: pointer;

    :hover {
        color: ${(props) => props.theme.black};
    }
`;

export const HRContainer = styled.hr`
    border: 1px solid ${(props) => props.theme.darkYellow};
    border-radius: 30px;
    width: 6%;
    max-width: 60px;
    min-width: 60px;
    margin: auto;
    height: 3px;
    background-color: ${(props) => props.theme.darkYellow};

}
`;
