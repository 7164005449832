import React, { useState } from 'react';
import styled from 'styled-components';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './App.css';
import Theme from './Theme';
import HomeContainer from './HomeContainer';
import HeaderContainer from './HeaderContainer';
import PrivacyContainer from './PrivacyContainer';
import FooterContainer from './FooterContainer';
import ScheduleFormContainer from './ScheduleFormContainer';
import ImageModalContainer from './ImageModalContainer';

const links = [
    {name: 'About'},
    {name: 'Features'},
    {name: 'Schedule a Demo'},
    {name: 'Contact Us'}
]

const MainContainer = styled.div`
    position: relative;

`
// class App extends Component {
//     this.state = {
//         displayModal: false
//     }

//     const setDisplayModal = (value) => {
//         this.setState({ displayModal: value })
//     }

//     render(){
//         return <ThemeProvider .../>
//     }
// }

const App = () => {
    const [displayModal, setDisplayModal] = useState(false);
    const [displayImageModal, setDisplayImageModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);

    // console.log(setDisplayImageModal);

    return (
        <ThemeProvider theme={Theme}>
            <MainContainer>
                { displayModal && <ScheduleFormContainer
                    hideModal={() => {
                        document.body.classList.remove('no-overflow')
                        setDisplayModal(false)
                    }} /> }
                { displayImageModal && <ImageModalContainer
                    currentImage={currentImage}
                    hideImageModal={() => {
                        document.body.classList.remove('no-overflow')
                        setDisplayImageModal(false)
                    }} /> }
                <HeaderContainer links={links} />
                <BrowserRouter>
                    <Switch>
                        <Route exact path='/'>
                            <HomeContainer
                                showModal={() => {
                                        document.body.classList.add('no-overflow')
                                        setDisplayModal(true)
                                    }
                                }
                                showImageModal={(image) => {
                                        document.body.classList.add('no-overflow')
                                        setDisplayImageModal(true)
                                        setCurrentImage(image)
                                        // console.log({image})
                                    }
                                }
                            />
                        </Route>
                        <Route path="/privacy-policy">
                            <PrivacyContainer />
                        </Route>
                    </Switch>
                </BrowserRouter>
                <FooterContainer />
            </MainContainer>
        </ThemeProvider>
    );
}

export default App;

