export default {
    mainColor: '#233C60',
    subColor:  '#FBB03E',
    white: '#FFFFFF',
    black: '#252525',
    context: '#222222',
    lightBlack: '#565656',
    lightBlue: '#1DAEAA',
    darkYellow: '#FB9D11',
    hoverYellow: '#F19103',
    hoverBlue: '#227775',
    gray: '#A2A2A2',
    transparentGray: '#A2A2A266',
    grayish: " #FCFCFC",
    lightGray: '#ECECEC',
    lighterGray: '#F7F7F7'
}