import React, { Fragment } from 'react';
import styled from 'styled-components';
import DefaultHeaderContainer from './DefaultHeaderContainer';
import { MotherContainer, FeatureTitle } from './Containers';

const PrivacyHeader = styled(MotherContainer)`
    background-color: ${props => props.theme.mainColor};
    color: ${props => props.theme.white};
    font-weight: 600;
    font-size: 26px;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @media (max-width: 480px) {
        padding: 3rem 2rem;
    }
`

const PrivacyBody = styled(MotherContainer)`
`

const PrivacyTitle = styled(FeatureTitle)`
    font-size: 18px;
    color: ${props=>props.theme.black};

`

const PrivacyContent = styled.span`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

`

const PrivacyList = styled.ul`
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    li {
        margin-left: 3rem;
        margin-top: 0.5rem;
    }
`



class PrivacyContainer extends React.Component {

    render() {

        const links = [
            // {name: 'Home'}
        ]

        return (
            <Fragment>
                <DefaultHeaderContainer links={links} />
                <PrivacyHeader id="firstComponent">Privacy Policy</PrivacyHeader>
                <PrivacyBody>

                    <PrivacyTitle>Data Privacy Policy</PrivacyTitle>
                    <PrivacyContent>
                        Pursuant to Republic Act No. 10173, also known as the Data Privacy Act of 2012, Intelimina Systems,
                        Inc. (“Intelimina”, “we”, “us”, or “our”) has designed this Data Privacy Policy which outlines how we
                        collect, utilize, process, store, and disclose your Personal Information through the use of our Apps,
                        Websites, and Services, particularly the OnlineDooze Website and Mobile App (collectively
                        “OnlineDooze”).
                    </PrivacyContent>

                    <PrivacyContent>
                        This Policy applies to our users, providers, partners, agents, staff, employees, and other stakeholders that
                        are part of OnlineDooze’s operations (collectively “you”, “your”, or “yours”).
                    </PrivacyContent>

                    <PrivacyContent>
                        “Personal Information” is any information which can be used to identify you or from which you are
                        identifiable. This may include, but is not limited to:
                    </PrivacyContent>
                    <PrivacyList>
                        <li>
                            Basic profile information such as your name, birth date, gender, nationality, and your photo;
                        </li>
                        <li>
                            Personal verifiable data such as government-issued identification numbers and biometric data;
                        </li>
                        <li>
                            Contact details including email, telephone and mobile numbers, and permanent or present
                        </li>
                        <li>
                            Payment information such as credit card or other online payment details;
                        </li>
                        <li>
                            Professional and/or business information, details regarding lifestyle, hobbies, and interests, and
                            other information which may be gathered from you.
                        </li>
                    </PrivacyList>

                    <PrivacyTitle>I. Collection of Personal Information</PrivacyTitle>

                    <PrivacyContent>
                        We collect Personal Information in the ways listed below.
                    </PrivacyContent>

                    <PrivacyTitle>When you voluntarily provide your Personal Information, such as when you:</PrivacyTitle>
                    <PrivacyList>
                        <li>
                            create and update your user profile;
                        </li>
                        <li>
                            interact with our social media pages;
                        </li>
                        <li>
                            participate in contests or events organized by us;
                        </li>
                        <li>
                            use biometric features to identity yourself; and
                        </li>
                        <li>
                            fill out demographic information in surveys.
                        </li>
                    </PrivacyList>

                    <PrivacyTitle>When our services are used, such as when you:</PrivacyTitle>
                    <PrivacyList>
                        <li>
                            create and manage units and amenities;
                        </li>
                        <li>
                            browse through our website and/or app;
                        </li>
                        <li>
                            give feedback and ratings; and
                        </li>
                        <li>
                            use the payment gateway within OnlineDooze.
                        </li>
                    </PrivacyList>

                    <PrivacyTitle>When we gather Personal Information from other sources, such as:</PrivacyTitle>
                    <PrivacyList>
                        <li>
                            referral programs;
                        </li>
                        <li>
                            our business partners and clients;
                        </li>
                        <li>
                            publicly available data;
                        </li>
                        <li>
                            governmental sources of data; and
                        </li>
                        <li>
                            marketing services providers or partners.
                        </li>
                    </PrivacyList>

                    <PrivacyTitle>II. Use of Personal Information</PrivacyTitle>

                    <PrivacyContent>
                        OnlineDooze may use, combine and process your Personal Information for the following purposes
                        (“Purposes”).
                    </PrivacyContent>

                    <PrivacyTitle>Providing Services</PrivacyTitle>
                    <PrivacyContent>
                        Your Personal Information will be used to provide, maintain, improve, and personalize OnlineDooze’s
                        products and services. This includes using your Personal Information to:
                    </PrivacyContent>

                    <PrivacyList>
                        <li>
                            provide you with Services across our platforms;
                        </li>
                        <li>
                            create, maintain, and update your account;
                        </li>
                        <li>
                            verify and validate your identity;
                        </li>
                        <li>
                            personalize your OnlineDooze experience;
                        </li>
                        <li>
                            conduct internal operations necessary to ensure smooth user experience, such as troubleshooting
                            and maintenance;
                        </li>
                        <li>
                            perform data gathering, monitoring, research, analysis, and reports for better delivery of services;
                        </li>
                        <li>
                            protect the security and integrity of the Services we provide;
                        </li>
                        <li>
                            manage your activities within OnlineDooze;
                        </li>
                        <li>
                            enable communications among our users, partners, and clients; and
                        </li>
                        <li>
                            enable our partners and clients to manage your Personal Information according to this Policy.
                        </li>
                    </PrivacyList>

                    <PrivacyTitle>Customer Support</PrivacyTitle>
                    <PrivacyContent>
                        We use your Personal Information to resolve customer support issues. For example, we may:
                    </PrivacyContent>
                    <PrivacyList>
                        <li>
                            investigate and address concerns;
                        </li>
                        <li>
                            monitor and improve our customer support responses;
                        </li>
                        <li>
                            respond to questions, comments and feedback; and
                        </li>
                        <li>
                            inform you about steps taken to resolve customer support issues.
                        </li>
                    </PrivacyList>

                    <PrivacyTitle>Legal purposes</PrivacyTitle>
                    <PrivacyContent>
                        We may use the Personal Information we collect to investigate and resolve claims or disputes, or as
                        allowed or required by applicable law.
                    </PrivacyContent>
                    <PrivacyContent>
                        We may also use your Personal Information when we are required, advised, recommended, expected or
                        requested to do so by our legal advisors or any local or foreign legal, regulatory, governmental or other
                        authority.
                    </PrivacyContent>
                    <PrivacyContent>
                        For example, we may use your Personal Information to:
                    </PrivacyContent>
                    <PrivacyList>
                        <li>
                            comply with court orders or other legal, governmental or regulatory requirements;
                        </li>
                        <li>
                            enforce our Terms of Service or other agreements; and
                        </li>
                        <li>
                            protect our rights or property in the event of a claim or dispute.
                        </li>
                    </PrivacyList>

                    <PrivacyTitle>Marketing and Promotions</PrivacyTitle>
                    <PrivacyContent>
                        We may use your Personal Information to market OnlineDooze and our partners’, sponsors’, and
                        advertisers’ products, services, events or promotions. For example, we may:
                    </PrivacyContent>
                    <PrivacyList>
                        <li>
                            send you alerts, newsletters, updates, mailers, promotional materials, special privileges, festive
                            greetings; and
                        </li>
                        <li>
                            notify, invite and manage your participation in our events or activities;
                        </li>
                    </PrivacyList>
                    <PrivacyContent>
                        We may communicate such marketing to you by post, telephone call, short message service, online
                        messaging service, push notification by hand and by email.
                    </PrivacyContent>
                    <PrivacyContent>
                        If you wish to unsubscribe to the processing of your Personal Information for marketing and promotions,
                        please click on the unsubscribe link in the relevant email or message. Alternatively, you may also update
                        your preferences in our settings.
                    </PrivacyContent>


                    <PrivacyTitle>III. Disclosure of Personal Information</PrivacyTitle>
                    <PrivacyContent>
                        We need to share your Personal Information with various parties for the Purposes. These parties include:
                    </PrivacyContent>

                    <PrivacyTitle>Building Managers, Unit Owners, and Tenants</PrivacyTitle>
                    <PrivacyContent>
                        For example, building managers and/or unit owners will be able to see a tenant’s Personal Information for
                        the generation of bills, scheduling of payments, and recording of payment receipts in the appropriate
                        tenant account.
                    </PrivacyContent>

                    <PrivacyTitle>OnlineDooze Partners and Affiliates</PrivacyTitle>
                    <PrivacyContent>
                        For example, if you requested a service through a OnlineDooze partner or used a promotion provided by
                        one of our partners, we may share your Personal Information with that partner. Our partners and affiliates
                        include partners that integrate with OnlineDooze or OnlineDooze integrates with, or business partners
                        which OnlineDooze collaborates with to deliver a promotion, competition, or other specialized service.
                    </PrivacyContent>

                    <PrivacyTitle>With OnlineDooze’s Service Providers and Business Partners</PrivacyTitle>
                    <PrivacyContent>
                        We may provide Personal Information to our vendors, consultants, marketing partners, research firms,
                        and other service providers or business partners. This includes:
                    </PrivacyContent>
                    <PrivacyList>
                        <li>
                            payment processors and facilitators;
                        </li>
                        <li>
                            background check and anti-money laundering service providers;
                        </li>
                        <li>
                            cloud storage providers;
                        </li>
                        <li>
                            marketing partners and marketing platform providers;
                        </li>
                         <li>
                            data analytics providers;
                        </li>
                        <li>
                            research partners, including those performing surveys or research projects in partnership with
                            OnlineDooze or on OnlineDooze’s behalf; and
                        </li>
                        <li>
                            merchant partners;
                        </li>
                    </PrivacyList>

                    <PrivacyTitle>With our legal advisers and governmental authorities</PrivacyTitle>
                    <PrivacyContent>
                        We may share your Personal Information with our legal advisors, law enforcement officials, government
                        authorities, and other third parties which have binding legal jurisdiction over such information.
                    </PrivacyContent>

                    <PrivacyTitle>IV. Storage and Retention of Personal Information</PrivacyTitle>
                    <PrivacyContent>
                        We retain your Personal Information for as long you maintain your OnlineDooze account. Once your
                        Personal Information is no longer necessary for the Services or Purposes, or we no longer have a legal or
                        business purpose for retaining your Personal Information, we take steps to prevent access or use of such
                        Personal Information for any purpose other than compliance with this privacy policy, or for purposes of
                        safety, security, fraud prevention and detection.
                    </PrivacyContent>

                    <PrivacyTitle>V. International Transfers of Personal Information</PrivacyTitle>
                    <PrivacyContent>
                        Information that we collect may be stored and processed in and transferred between any of the countries
                        in which we make use of cloud services (e.g. Office 365, Amazon Web Services) in order to
                        enable us to use your information in accordance with this policy.
                    </PrivacyContent>
                    <PrivacyContent>
                        Personal information that you publish on our website or submit for publication on our website may be
                        available, via the Internet, around the world. We cannot prevent the use or misuse of such information by
                        others.
                    </PrivacyContent>

                    <PrivacyTitle>VI. Cookies</PrivacyTitle>
                    <PrivacyContent>
                        OnlineDooze, and third parties with whom we partner, may use cookies, web beacons, tags, scripts, local
                        shared objects such as HTML5 and Flash (sometimes called “flash cookies”), advertising identifiers
                        (including mobile identifiers such as Apple’s IDFA or Google’s Advertising ID) and similar technology
                        (“Cookies”) in connection with your use of the Websites and Apps. Cookies may have unique identifiers,
                        and reside, among other places, on your computer or mobile device, in emails we send to you, and on our
                        web pages. Cookies may transmit Personal Information about you and your use of the Service, such as
                        your browser type, search preferences, IP address, data relating to advertisements that have been
                        displayed to you or that you have clicked on, and the date and time of your use. Cookies may be persistent
                        or stored only during an individual session.
                    </PrivacyContent>
                    <PrivacyContent>
                        OnlineDooze may allow third parties to use Cookies on the Websites and Apps to collect the same type of
                        Personal Information for the same purposes OnlineDooze does for itself. Third parties may be able to
                        associate the Personal Information they collect with other Personal Information they have about you from
                        other sources. We do not necessarily have access to or control over the Cookies they use.
                    </PrivacyContent>
                    <PrivacyContent>
                        Additionally, we may share non-personally identifiable Personal Information with third parties, such as
                        location data, advertising identifiers, or a cryptographic hash of a common account identifier (such as an
                        email address), to facilitate the display of targeted advertising.
                    </PrivacyContent>
                    <PrivacyContent>
                        If you do not wish for your Personal Information to be collected via Cookies on the Websites, you may
                        deactivate cookies by adjusting your internet browser settings to disable, block or deactivate cookies, by
                        deleting your browsing history and clearing the cache from your internet browser. You may also be able
                        to limit our sharing of some of this Personal Information through your mobile device settings, or by
                        submitting your details to us.
                    </PrivacyContent>

                    <PrivacyTitle>VII. Protection of Personal Information</PrivacyTitle>
                    <PrivacyContent>
                        We will take reasonable legal, organizational, and technical measures to ensure that your Personal
                        Information is protected and secure. This includes measures to prevent data from getting lost, or used or
                        accessed in an unauthorized way. We limit access to your Personal Information to our employees on a
                        need to know basis. Those processing your personal data will only do so in an authorized manner and are
                        required to treat your information with confidentiality.
                    </PrivacyContent>
                    <PrivacyContent>
                        In detail, we ensure the security of your Personal Information by implementing the following measures:
                    </PrivacyContent>
                     <PrivacyList>
                        <li>
                            Access to Personal Information shall be restricted to authorized users.
                        </li>
                        <li>
                            Personal Information shall be protected using a secured server behind a firewall, data
                            encryption and layered security controls.
                        </li>
                        <li>
                            Audit and testing of infrastructure equipment shall be regularly performed to guarantee data
                            security.
                        </li>
                    </PrivacyList>

                    <PrivacyContent>
                        Nevertheless, please understand that the transmission of information via the Internet is not completely
                        secure. Although we will do our best to protect your Personal Information, we cannot guarantee the
                        security of your data transmitted through any online means, therefore, any transmission remains at your
                        own risk.
                    </PrivacyContent>

                    <PrivacyTitle>VIII. Amendments and Updates</PrivacyTitle>
                    <PrivacyContent>
                        OnlineDooze shall have the right to modify, update, or amend the terms of this Policy at any time by
                        placing the updated Policy on our Websites and Apps. By continuing to use the Apps, Websites, or
                        Services, purchase products from OnlineDooze or continuing to communicate or engage with
                        OnlineDooze following the modifications, updates or amendments to this Policy, you signify your
                        acceptance of such modifications, updates, or amendments.
                    </PrivacyContent>

                    <PrivacyTitle>IX. Management and Security</PrivacyTitle>
                    <PrivacyContent>
                        A Data Privacy Officer for OnlineDooze and corresponding Compliance Officers of Privacy for our
                        subsidiaries have been appointed to manage and safeguard the handling of your Personal
                        Information in compliance to the Philippine Data Privacy Act of 2012 or RA 10173 and this
                        Policy. OnlineDooze Management is committed to ensure that its Privacy Management Program is
                        constantly reviewed, monitored and enhanced. OnlineDooze regards personal information
                        breaches very seriously.
                    </PrivacyContent>

                    <PrivacyContent>
                        If you have any queries about your Personal Information, please contact our Data Privacy Officer at:
                        <a href="https://mail.google.com/mail/?view=cm&fs=1&to=privacy@intelimina.com"
                        class="text-underline">privacy@intelimina.com.</a>
                    </PrivacyContent>



                </PrivacyBody>
            </Fragment>
        );
    }
}

export default PrivacyContainer;
