import React, { Fragment } from 'react';
import styled from 'styled-components';
import { InputField, InputLabel, InputFormContainer,
    HeaderNameContainer, HeadFatherContainer } from './Containers';
import { DefaultButton, ImageContainer, SubmitButton, ContentTitle,
    HRContainer } from './Styles';
import ModulesContainer from './ModulesContainer';
import FeaturesContainer from './FeaturesContainer';
import webImage02 from './images/WebImage02.png';
import banner01 from './images/banner01.png';
import { FatherContainer } from './Containers';


// const Home = styled(MotherContainer)`
//     min-height: 75vh;
//     height: auto;
// `

const FirstContent = styled(HeadFatherContainer)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 100%;
    margin: auto;
    padding-top: 5rem;
    background-color: ${props => props.theme.grayish}
    background-image: ${ props => `url(${props.background})` };
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 10rem 8rem;

    @media (max-width: 480px) {
        flex-direction: column;
    }
`
const SecondContent = styled(FatherContainer)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    max-width: 1440px;
    margin: auto;

    @media (max-width: 768px) {
        justify-content: space-around;
        padding-top: 0;
    }
`

const ThirdContent = styled(FatherContainer)`
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    margin: auto;
    background-color: ${props => props.theme.grayish};

    @media (max-width: 890px) {
    }

    @media (max-width: 480px) {
        margin: 0;
        padding: 0 1rem;
    }
`

const FourthContent = styled(FatherContainer)`
    align-items: baseline;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: ${props => props.theme.grayish};
    padding: 0 8rem 3.5rem 8rem;
    margin-bottom: 0;

    @media (max-width: 890px) {
        flex-direction: column;
        padding-top: 0;
    }

    @media (max-width: 480px) {
        margin: 0;
        padding: 0 1rem;
        padding-bottom: 3rem;
    }
`

const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${props => props.theme.black}

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    @media (max-width: 480px) {
        align-items: center;
    }
`

const Context = styled.p`
    font-size: 16px;
    text-align: left;
    width: 90%;
    line-height: 1.5;
    color: ${props => props.theme.lightBlack}
    font-family: 'Roboto', sans-serif;

    @media (max-width: 768px) {
        text-align: center;
        margin-top: 1rem;
    }
`

const RightContent = styled.div`
`

const WebImageContainer = styled(ImageContainer)`
    background-image: ${ props => `url(${props.background})` };
    height: 180px;
    width: 100%;
    max-width: 500px;
    background-position: right;

    @media (max-width: 768px) {
        background-position: center;
    }

    @media (max-width: 480px) {
        max-width: 350px;
        height: 180px;
        margin: 1rem 0;
    }
`

const TextAreaContainer = styled.textarea`
    margin: "0.2rem 0 1rem 0";
    height: 150px;
    width: 100%;
    font-size: 12px;
    padding: 1rem;
    border: 1px solid ${props => props.theme.gray};
    border-radius: 10px;
    color: ${props => props.theme.lightBlack};

    ::placeholder {
        font-family: 'Roboto', sans-serif;
        color: ${props => props.theme.lightBlack};
        font-size: 16px;
    }
`

const ContactLeftContainer = styled(LeftContent)`
    width: 48%;

    @media (max-width: 890px) {
        width: 100%;
    }
`

const ContactRightContainer = styled(RightContent)`
    width: 48%;

    @media (max-width: 890px) {
        width: 100%;
    }
`

const ContactContent = styled.p`
    font-size: 16px;
    width: 90%;
    line-height: 1.5;
    margin-bottom: 2rem;
    color: ${props => props.theme.lightBlack};
    font-family: 'Roboto', sans-serif;
`

const ContactHeader = styled(ContactContent)`
    font-weight: 600;
    margin-bottom: 0;
`

const ScheduleButton = styled(DefaultButton)`
    width: 240px;
    background-color: ${ props => props.background };
    color: ${ props => props.theme.white };
    border: none !important;
    text-transform: uppercase;
    margin-top: 1.5rem;
    border: 1px solid black;
    height: 52px;
`

const ContextHeader = styled(Context)`
    color: gray;
    text-align: center;
    font-size: 16px;
    margin: auto;
`

const BannerText = styled(Context)`
    color: white;
    text-align: center;
    font-size: 18px;
`

class HomeContainer extends React.Component {

    render() {

        return (
            <Fragment>
                <FirstContent background={banner01}
                    id="firstComponent">
                    <LeftContent>
                        <ContentTitle style={{
                            color: `white`, marginTop: `4rem`, marginBottom: `0.5rem`, fontSize: `32px` }}>
                                Welcome to OnlineDooze
                        </ContentTitle>
                        <BannerText>
                            The on the go, on the dot, one-stop online
                        </BannerText>
                        <BannerText style={{ width: `100%`, marginTop: `0` }}>
                            portal for an organized and efficient property management.
                        </BannerText>
                    </LeftContent>
                    {
                        <div className="display-none">
                            <WebImageContainer
                            style={{ marginTop: `4rem` }}
                            background={webImage02} />
                        </div>
                    }
                </FirstContent>
                <HeaderNameContainer
                    id="About">
                    <HRContainer />
                    <ContentTitle style={{ fontSize: `24px`, marginTop: `0.5rem` }}>
                        About the software
                    </ContentTitle>
                </HeaderNameContainer>
                <SecondContent
                    style={{ paddingBottom: `3.5rem` }}>
                    <ModulesContainer />
                </SecondContent>
                <HeaderNameContainer
                    id="Features"
                    backgroundColor={props=> props.theme.grayish}
                    style={{ paddingBottom: `0` }}>
                        <HRContainer />
                        <ContentTitle style={{ fontSize: `24px`, marginTop: `0.5rem` }}>
                            Features
                        </ContentTitle>
                </HeaderNameContainer>
                <ThirdContent style={{ paddingTop: `0` }}>
                    <FeaturesContainer
                        showImageModal={this.props.showImageModal}
                    />
                </ThirdContent>
                <HeaderNameContainer
                    id="Schedule a Demo">
                    <HRContainer />
                    <ContentTitle style={{
                            fontSize: `24px`,
                            marginTop: `0.5rem`,
                        }}>
                            Ready to see more?
                    </ContentTitle>
                    <ContextHeader style={{
                            marginTop: `0.5rem`,
                        }}>
                            Give us a few details to customize your demo with us.
                    </ContextHeader>
                    <ContextHeader>
                        If you have any other inquiries, you may send us a message below.
                    </ContextHeader>
                    <ScheduleButton
                        onClick={this.props.showModal}>
                            Schedule a Demo Presentation
                    </ScheduleButton>
                </HeaderNameContainer>
                <HeaderNameContainer
                    id="Contact Us"
                    backgroundColor={props=> props.theme.grayish}
                    className="contact-mobile">
                        <HRContainer />
                        <ContentTitle style={{ fontSize: `24px`, marginTop: `0.5rem` }}>
                            Contact Us
                        </ContentTitle>
                        <ContextHeader style={{
                            color: `gray`,
                            textAlign: `center`,
                            fontSize: `16px`,
                            margin: `auto`,
                            marginTop: `0.5rem`,
                        }}>
                            Let's talk! Give us a call or message us through our contact form.
                        </ContextHeader>
                </HeaderNameContainer>
                <FourthContent>
                    <ContactLeftContainer>
                        <ContactContent>Think we can help with your project? Let's talk! Give us a call or message us through our contact form.</ContactContent>

                        <ContactHeader>Principal Business Address</ContactHeader>
                        <ContactContent>Unit 305, Prince David Condominium, Katipunan Avenue, Loyola Heights, Quezon City 1108</ContactContent>

                        <ContactHeader>Phone Number</ContactHeader>
                        <ContactContent>(+632) 8932 74 47</ContactContent>
                    </ContactLeftContainer>
                    <ContactRightContainer>
                        <InputFormContainer
                            action="https://formspree.io/info@intelimina.com"
                            method="POST">
                            <InputLabel>Name (optional)</InputLabel>
                            <InputField name="name" type="text" />
                            <InputLabel>Email<span className="bg-required">*</span></InputLabel>
                            <InputField name="email" type="email" placeholder="e.g. sample@email.com" required/>
                            <InputLabel>Message<span className="bg-required">*</span></InputLabel>
                            <TextAreaContainer name="message" type="text" placeholder="Type your message here" required />
                            <SubmitButton
                                style={{ display: `block`, marginLeft: `auto` }}
                                backgroundColor={props=> props.theme.lightBlue}>
                                    Send Message
                            </SubmitButton>
                        </InputFormContainer>
                    </ContactRightContainer>
                </FourthContent>
            </Fragment>
        );
    }
}

export default HomeContainer;
