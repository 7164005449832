import React, { Component } from 'react';
import styled from 'styled-components';
import { FooterBox } from './Containers';
import { Header } from './HeaderContainer';

const Footer = styled(Header)`
    align-items: center;
    color: white;
    background-color: ${props => props.theme.mainColor}
    height: auto;
    justify-content: space-between;

    a:hover {
        cursor: pointer;
    }

    @media (max-width: 480px) {
        flex-direction: column;
    }

`

const FirstFooterBox = styled(FooterBox)`
    font-size: 14px;

    p {
    }

    a {
        color: inherit;
        text-decoration: none;
    }

`

const SecondFooterBox = styled(FooterBox)`
    text-align: right
    a {
        margin-top: 5px;
    }

    a:hover {
        cursor: pointer;
    }

`

// const FooterTitle = styled.p`
//     font-weight: bold;
//     margin-bottom: 1.2rem;
// `

class FooterContainer extends Component {

    render() {

        return (
            <Footer>
                <FirstFooterBox>
                    <p><a href="/privacy-policy">{`Privacy Policy`}</a></p>
                </FirstFooterBox>
                <SecondFooterBox>
                    <p>&#169;{` OnlineDooze. All rights reserved. 2019`}</p>
                </SecondFooterBox>
            </Footer>
        );
    }
}

export default FooterContainer;
