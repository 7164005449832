import React, { Fragment } from 'react';
import styled from 'styled-components';
import { MotherContainer, LogoContainer, FlexContainer, FlexColumnContainer } from './Containers';
import logoColored from './images/LogoColored.png';
import { DefaultButton } from './Styles';


const MaxWidthContainer = styled.div`
    position: relative;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
`

export const Header = styled(MotherContainer)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 480px) {
        justify-content: center;
    }

`

const LinksContainer = styled.div`
    display: flex;
    justify-content: flex-end
    width: 80%;
    margin-left: auto;
    font-size: 16px;
    flex-direction: row;
    margin-top: 2px;
    color: ${props => props.theme.black}
    font-family: 'Roboto', sans-serif;

    a {
        cursor: pointer;
        margin-left: ${props => props.showNavi && props.isMobile ? '3.5rem' : '1.5rem'};
        text-decoration: none;
        color: inherit;
    }

`

const NavigationLeftContainer = styled(FlexContainer)`
    align-items: center;
    margin-right: auto;

`

const NavigationRightContainer = styled(FlexContainer)`
    align-items: center;
    margin-left: auto;
`

const LoginButton = styled(DefaultButton)`
    margin-top: 0;
    font-size: 16px;
    background: none;
    border: 1px solid none;
    color: ${props => props.theme.black};
    width: 110px;
    min-width: 100px;
    margin-right: 1rem;
    height: 40px;

    @media (max-width: 768px) {
        border: 1px solid ${props => props.theme.white};
        color: ${props => props.theme.black};
        font-weight: 600;
    }

    :hover {
        background: none;
    }
`

const RegisterButton = styled(LoginButton)`
    background-color: ${props => props.theme.darkYellow};
    color: ${props => props.theme.white};;
    border: none;
    font-size: 16px;

    :hover {
        background-color: ${props => props.theme.hoverYellow};
    }
`

const MobileNavigationContainer = styled(MotherContainer)`
    display: ${props => props.showNavi && props.isMobile ? 'flex' : 'none'};
    background-color: ${props => props.theme.grayish}
    align-items: center;
    text-align: center;
    justify-content: center;

    button {
        margin: ${props => props.showNavi && props.isMobile ? '0.5rem 0' : 'none'};
    }

    a {
        margin: ${props => props.showNavi && props.isMobile ? '0.5rem 0' : 'none'};
    }

    @media (max-width: 768px) {
        position: absolute;
        background-color: transparent;
        width: 100%;
        padding-top: 0;
    }
`

class DefaultHeaderContainer extends React.Component {
    constructor(props) {
        super();

        this.state = {
            showNavi: false,
            widthDevice: document.documentElement.clientWidth,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.setWidth.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setWidth.bind(this));
    }

    setWidth (e) {
        this.setState({ widthDevice: document.documentElement.clientWidth });
    }

    goToThisLink(e, id) {

        e.preventDefault();

        var $ = require("jquery");

        if ( id ==="Home" ) {

            window.location.href = '/';

        } else {

            const position = document.getElementById(id).offsetTop;

            $("body, html").animate({ scrollTop: position }, 500 );

        }

    }

    goToThisURL() {

        window.location.href="https://app.onlinedooze.com/admin";

    }

    logoHome() {

        window.location.href = '/';

    }

    burgerChange(e) {

        const { showNavi } = this.state;
        var elem = document.getElementById('firstComponent');

        // console.log(showNavi);
        this.setState({ showNavi: !showNavi });
        if (showNavi === false) {
            elem.setAttribute("style", "margin-top: 8rem;");
        } else {
            elem.setAttribute("style", "margin-top: 2rem;");
        }
    }

    render() {

        const { showNavi, widthDevice } = this.state;

        const { links } = this.props;

        const isMobile = widthDevice < 890;

        // console.log(widthDevice);
        // console.log(isMobile);
        // console.log( showNavi );

        return (
            <Fragment>
                <MaxWidthContainer>
                    <Header>
                        {
                            isMobile
                            ?   <Fragment>
                                    <NavigationLeftContainer>
                                        <LogoContainer onClick={()=>this.logoHome()} src={logoColored}
                                        />
                                    </NavigationLeftContainer>
                                    <NavigationRightContainer>
                                        <div className={`container ${showNavi ? 'change' : ''}`}
                                            onClick={(e)=>this.burgerChange(e)} >
                                            <div className="bar1" style={{ backgroundColor: `#252525` }}></div>
                                            <div className="bar2" style={{ backgroundColor: `#252525` }}></div>
                                            <div className="bar3" style={{ backgroundColor: `#252525` }}></div>
                                        </div>
                                    </NavigationRightContainer>
                                </Fragment>
                            :   <Fragment>
                                    <NavigationLeftContainer>
                                        <LogoContainer onClick={()=>this.logoHome()} src={logoColored} />
                                        <LinksContainer>
                                            {links.map((l, i) => (
                                                <a href={`#${l.name}`}
                                                    onClick={(e)=>this.goToThisLink(e, l.name)}
                                                    key={i}>
                                                        {l.name}
                                                </a>
                                            ))}
                                        </LinksContainer>
                                    </NavigationLeftContainer>
                                    <NavigationRightContainer>
                                        <LoginButton
                                            onClick={()=>this.goToThisURL()}>
                                                Log in
                                        </LoginButton>
                                        <RegisterButton>
                                            Sign up
                                        </RegisterButton>
                                    </NavigationRightContainer>
                                </Fragment>
                        }
                    </Header>
                </MaxWidthContainer>
                {
                    showNavi && isMobile &&
                    <MobileNavigationContainer
                        showNavi={showNavi}
                        isMobile={isMobile}>
                        <FlexColumnContainer>
                            <LinksContainer style={{ flexDirection: `column`, width: `100%`, fontSize: `16px` }}>
                                {links.map((l, i) => (
                                    <a href={`#${l.name}`}
                                        onClick={(e)=>this.goToThisLink(e, l.name)}
                                        key={i}>
                                            {l.name}
                                    </a>
                                ))}
                            </LinksContainer>
                            <LoginButton
                                onClick={()=>this.goToThisURL()}>
                                    Log in
                            </LoginButton>
                            <RegisterButton style={{ color: `white` }}>
                                Sign up
                            </RegisterButton>
                        </FlexColumnContainer>
                    </MobileNavigationContainer>
                }
            </Fragment>

        );
    }
}

export default DefaultHeaderContainer;
