import React, { Component } from 'react';
import styled from 'styled-components';
import { FlexContainer, FlexColumnContainer, FlexColReverseContainer } from './Containers';
import { ImageContainer, ContentTitle } from './Styles';
import { FiBox, FiUser, FiUsers, FiCreditCard, FiHome, FiFileText, FiSmartphone } from "react-icons/fi";
// import { NavLink } from 'react-router-dom';

import ownerImg01 from './images/unitManagement.png';
import ownerImg02 from './images/tenantManagement.png';
import ownerImg03 from './images/ownerPayment.png';
import ownerImg04 from './images/ownerAmenity.jpg';

import tenantImg01 from './images/digitalBills.png';
import tenantImg02 from './images/onlinePayment.png';
import tenantImg03 from './images/tenantPayment.png';
import tenantImg04 from './images/tenantAmenity.png';


const FeaturesList = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
    font-size: 24px;

    @media (max-width: 890px) {
        margin-left: 3.5rem;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    @media (max-width: 480px) {
        margin-left: 0;
    }

    li {
        display: flex;
        line-height: 1;
        cursor: pointer;
        border-radius: 28px;
        padding: 10px 18px;
        background-color: ${props => props.theme.lighterGray};
        margin-bottom: 1rem;
        margin-right: 1rem;

        button {
            color: ${props => props.theme.lightBlack};
            text-decoration: none;
            font-size: 14px;
            border: none;
            background-color: Transparent;
            font-family: 'Roboto', sans-serif;
            font-weight: 600;
        }

        &.active {
            background-color: ${props => props.theme.darkYellow};
            color: ${props => props.theme.white};

            button {
                color: ${props => props.theme.white};
            }
        }

        @media (max-width: 890px) {
            min-width: 140px;
            justify-content: center;
            margin: 0;
            margin-bottom: 1rem;

            button {
                font-size: 14px;
            }
        }
    }

    li:last-child {
        margin-right: 0;
    }

    li:hover, li:active {
        color: white;
        background-color: ${props => props.theme.darkYellow}

        button {
            color: ${props => props.theme.white};
            background-color: ${props => props.theme.darkYellow};
            font-weight: 600;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;
    }
`

const Features = styled.div`
    width: 100%;

`

const FeatureBox = styled(FlexColReverseContainer)`
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 3rem;

    @media (max-width: 890px) {
        flex-direction: column-reverse;
        margin-bottom: 1rem;
        padding-bottom: 0;
    }
`

const FeatureContent = styled(FlexColumnContainer)`

`

const ScreenshotContainer = styled(ImageContainer)`
    background-image: ${ props => `url(${props.background})` };
    width: 100%;
    max-width: 1440px;
    transition: 0.5s;
    animation-timing-function: ease;
    cursor: pointer;
    -webkit-box-shadow: 5px 5px 25px -11px rgba(122,122,122,0.5);
    -moz-box-shadow: 5px 5px 25px -11px rgba(122,122,122,0.5);
    box-shadow: 5px 5px 25px -11px rgba(122,122,122,0.5);
    background-size: cover;
    background-position: top;
    padding-top: 56.25%;
    margin-top: 1.5rem;

    @media (min-width: 1240px) {
        background-size: cover;
        padding-top: 47.25%;
    }

    @media (max-width: 890px) {
        width: auto;
        height: 300px;
    }

    @media (max-width: 480px) {
        width: 100%;
        background-size: 100% 100%;
        background-position: left;
        height: 210px;
    }
`

// const FeatureHeader = styled(FeatureTitle)`
//     font-size: 16px;
//     margin: 0.5rem 0;

//     @media (max-width: 890px) {
//         margin-left: 3.5rem;
//         margin-top: 1.5rem;
//     }

//     @media (max-width: 480px) {
//         font-size: 20px;
//         margin-left: 0;
//     }
// `

const ForModalHeader = styled(FlexContainer)`
    padding: 1.5rem 0;
    justify-content: center;
`

const ForModalTitle = styled(ContentTitle)`
    font-size: 18px;
    font-weight: 500;
    color: ${ props => props.theme.lightBlack };
    cursor: pointer;

    @media (max-width: 480px) {
        font-size: 14px;
    }
`

const OwnerFeatures = [
    {name: 'Unit Management', image: ownerImg01 },
    {name: 'Tenant Management', image: ownerImg02 },
    {name: 'Billing and Payment', image: ownerImg03 },
    {name: 'Amenity Management', image: ownerImg04 },
]

const TenantFeatures = [
    {name: 'Digital Bills', image: tenantImg01 },
    {name: 'Online Payment', image: tenantImg02 },
    {name: 'Billing and Payment', image: tenantImg03 },
    {name: 'Amenity Management', image: tenantImg04 },
]

class FeaturesContainer extends Component {
    constructor(props) {
        super();

        this.state = {
            currentOwner: 0,
            currentTenant: 0,
            currentOwnerImg: ownerImg01,
            currentTenantImg: tenantImg01,
            isOwner: true,
            isTenant: false,
        };
    }

    setCurrentOwner(e, index) {
        this.setState({ currentOwner: index });
    }

    setCurrentTenant(e, index) {
        this.setState({ currentTenant: index });
    }

    toggleOwnerImage(e, image) {
        this.setState({ currentOwnerImg: image });
    }

    toggleTenantImage(e, image) {
        this.setState({ currentTenantImg: image });
    }

    toggleShowOwnersModal(e) {
        this.setState({ isOwner: true, isTenant: false });
    }

    toggleShowTenantsModal(e) {
        this.setState({ isTenant: true, isOwner: false });
    }

    toggleIcons(name) {
        if (name && name === "Unit Management") {
            return <FiBox />
        } else if (name && name === "Tenant Management") {
            return <FiUsers />
        } else if (name && name === "Billing and Payment") {
            return <FiCreditCard />
        } else if (name && name === "Amenity Management") {
            return <FiHome />
        } else if (name && name === "Digital Bills") {
            return <FiFileText />
        } else if (name && name === "Online Payment") {
            return <FiSmartphone />
        }
    }

    render() {

        const { currentOwner, currentTenant, isOwner } = this.state;
        // const { showImageModal } = this.props;
        const currentOwnerImg = OwnerFeatures[currentOwner].image;
        const currentTenantImg = TenantFeatures[currentTenant].image;

        // console.log(showImageModal);
        // console.log(isOwner);
        // console.log(isTenant);

        return (
            <Features>
                <ForModalHeader>
                    <ForModalTitle onClick={() => this.toggleShowOwnersModal()}
                        className={`icon-div ${isOwner ? "main" : "" }`}
                        style={{ marginRight: `3rem` }}>
                            <span className={`circle-border m-r-small ${isOwner ? "bg-darkyellow" : "bg-lightgray"}`}>
                                <FiUser/>
                            </span>
                            For Owners
                    </ForModalTitle>
                    <ForModalTitle onClick={() => this.toggleShowTenantsModal()}
                        className={`icon-div ${isOwner ? "" : "main" }`}>
                            <span className={`circle-border m-r-small ${isOwner ? "bg-lightgray" : "bg-darkyellow"}`}>
                                <FiUsers/>
                            </span>
                            For Tenants
                    </ForModalTitle>
                </ForModalHeader>
                {
                    isOwner
                    ?   (<FeatureBox>
                            <ScreenshotContainer
                                onClick={() => this.props.showImageModal(currentOwnerImg)}
                                background={currentOwnerImg}
                            />
                            <FeatureContent>
                                <FeaturesList>
                                    {
                                        OwnerFeatures.map((o, index) => (
                                        <li className={`${ index === currentOwner ? 'active' : '' }`}
                                            key={index}
                                            onClick={(e)=>this.setCurrentOwner(e, index)}>
                                            <span className={`icon-margin`}>
                                                {
                                                    this.toggleIcons(o.name)
                                                }
                                            </span>
                                            <button>
                                                {o.name}
                                            </button>
                                        </li>
                                    ))}
                                </FeaturesList>
                            </FeatureContent>
                        </FeatureBox>)
                    :   (<FeatureBox>
                            <ScreenshotContainer
                                onClick={() => this.props.showImageModal(currentTenantImg)}
                                background={currentTenantImg}
                            />
                            <FeatureContent>
                                <FeaturesList>
                                    {
                                        TenantFeatures.map((t, index) => (
                                        <li className={`${ index === currentTenant ? 'active' : '' }`}
                                            key={index}
                                            onClick={(e)=>this.setCurrentTenant(e, index)}>
                                            <span className={`icon-margin`}>
                                                {
                                                    this.toggleIcons(t.name)
                                                }
                                            </span>
                                            <button>
                                                {t.name}
                                            </button>
                                        </li>
                                    ))}
                                </FeaturesList>
                            </FeatureContent>
                        </FeatureBox>)
                }
            </Features>
        );
    }
}

export default FeaturesContainer;

