import React from "react";
import styled from "styled-components";

export const MotherContainer = styled.div`
    padding: 1rem 8rem;

    @media (max-width: 768px) {
        padding: 1rem 4rem;
    }

    @media (max-width: 480px) {
        padding: 1rem 2rem;
    }
`;

export const FatherContainer = styled.div`
    padding: 0 8rem;

    @media (max-width: 768px) {
        padding: 0 4rem;
    }

    @media (max-width: 480px) {
        padding: 3rem;
    }
`;

export const HeadFatherContainer = styled.div`
    padding: 6rem 8rem;

    @media (max-width: 768px) {
        padding: 8rem 4rem 10rem 4rem;
    }

    @media (max-width: 480px) {
        padding: 6rem 2rem 6rem 2rem;
    }
`;

export const LogoContainer = styled.img`
    display: inline-block;
    height: 50px;
    min-width: 165px;
    cursor: pointer;
    margin-bottom: 3px;
`;

export const FooterBox = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px;
`;

export const FlexContainer = styled.div`
    display: flex;
`;

export const FlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexColReverseContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
`;

const Module = styled(FlexColumnContainer)`
    background-color: ${(props) => props.theme.white};
    border: 1px solid #dbdbdb;
    display: inline-block
    border-radius: 15px;
    text-align: center;
    padding: 1.5rem;
    width: 28%;
    margin-bottom: 2rem;
    min-width: 315px;
    height: 440px;

    p {
        font-size: 16px;
        color: ${(props) => props.theme.context};
        line-height: 1.5;
        font-family: 'Roboto', sans-serif;
    }

    @media (max-width: 480px) {
        height: auto;
    }

`;

export const FeatureTitle = styled.div`
    font-size: 18px;
    color: ${(props) => props.theme.mainColor};
    margin: 1rem 0;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
`;

const ModuleIconContainer = styled.div`
    margin: auto;
    padding: 14px;
    border-radius: 42px;
    background-color: ${(props) => props.theme.grayish};
    width: 30%;
    min-width: 85px;
    max-width: 85px;
`;

const ModuleIcon = styled.div`
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${(props) => `url(${props.icon})`};
    min-width: 55px;
    min-height: 55px;
`;

export const ModuleBox = ({ icon, title, context }) => (
    <Module>
        <ModuleIconContainer>
            <ModuleIcon icon={icon} />
        </ModuleIconContainer>
        <FeatureTitle>{title}</FeatureTitle>
        <p>{context}</p>
    </Module>
);

export const InputField = styled.input`
    font-size: 16px;
    font-weight: 600;
    padding: 1rem;
    width: 100%;
    border: 1px solid ${(props) => props.theme.gray};
    color: ${(props) => props.theme.lightBlack};
    border-radius: 10px;
    margin-bottom: 0.5rem;

    ::placeholder {
        color: ${(props) => props.theme.lightBlack};
    }
`;

export const InputLabel = styled.p`
    font-size: 16px;
    margin: 5px 0;
    color: ${(props) => props.theme.lightBlack};
    font-weight: 600;
`;

export const InputFormContainer = styled.form`
    line-height: 2;
`;

export const CloseButtonContainer = styled.div`
    display: flex;

    p {
        margin-left: auto;
        font-size: 24px;
        color: ${(props) => props.theme.lightBlack};
        cursor: pointer;
    }
`;

export const HeaderNameContainer = styled.div`
    padding: 3.5rem;
    text-align: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};

    @media (max-width: 480px) {
        padding: 3rem 1.5rem;
    }
`;
