import React from 'react';
import styled from 'styled-components';
import { CloseButtonContainer } from './Containers';
import { CloseButton } from './Styles';


const ScreenshotModalContainer = styled.div`
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    position: fixed;
`

const ScreenshotModal = styled.div`
    background-image: ${ props => `url(${props.background})` } !important;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    height: 100%;
    max-height: 800px;
    width: 90%;
    z-index: 5;

    @media (max-width: 480px) {
        overflow-y: scroll;
        width: 95%;
        height: 100%;
        z-index: 0;
        padding: 0;
    }
`

const ScreenshotCloseButton = styled(CloseButton)`
    color: white !important;
    font-size: 35px !important;
    margin-right: 30px;
    margin-top: 15px;
    cursor: pointer;

    @media (max-width: 480px) {
        z-index: 6;
        margin-right: 3%;
    }
`

class ImageModalContainer extends React.Component {
    render() {

        return (
            <ScreenshotModalContainer>
                <CloseButtonContainer>
                    <ScreenshotCloseButton onClick={this.props.hideImageModal}>&times;</ScreenshotCloseButton>
                </CloseButtonContainer>
                <ScreenshotModal background={this.props.currentImage} />
            </ScreenshotModalContainer>
        )
    }
}

export default ImageModalContainer;


