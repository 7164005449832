import React from 'react';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputField, InputLabel, InputFormContainer, CloseButtonContainer, LogoContainer,
    FlexContainer, FlexColumnContainer } from './Containers';
import { SubmitButton, ContentTitle, CloseButton } from './Styles';
import calendar from './images/calendar.png';


const ScheduleContainer = styled.div`
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    position: fixed;
`

const ScheduleForm = styled.div`
    padding: 3rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    background: white;
    width: 60%;
    z-index: 5;
    max-width: 700px;

    @media (max-width: 480px) {
        padding: 1rem;
        width: 100%;
        overflow-y: scroll;
        height: 80%;
    }
`

const ScheduleInputForm = styled(InputFormContainer)`
    button {
        text-align: center;
        align-items: center;
    }
`

const DateContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
`

const ScheduleHeader = styled(FlexContainer)`
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    @media (max-width: 480px) {
        flex-direction: column;

        p {
            font-size: 20px;
        }
    }
`

const LogoCalendar = styled(LogoContainer)`
    margin-right: 25px;
    width: 100px;
    height: 60px;
    min-width: 75px !important;
    max-width: 75px !important;

    @media (max-width: 480px) {
        margin-right: 0;
        height: 55px;
    }
`

const SmallInfo = styled(ContentTitle)`
    color: ${props => props.theme.gray};
    font-size: 16px !important;

    @media (max-width: 480px) {
        font-size: 16px !important;
    }
`

const SubmitRequest = styled(SubmitButton)`
    width: 180px;
    min-width: 175px;
`

class ScheduleFormContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: new Date(),
            startTime: new Date()
        };

    }

    handleChangeDate(date) {
        this.setState({ startDate: date });
    };

    handleChangeTime(time) {
        this.setState({ startTime: time });
    };

    render() {

        return (
            <ScheduleContainer>
                <ScheduleForm>
                    <CloseButtonContainer>
                        <CloseButton onClick={this.props.hideModal}>&times;</CloseButton>
                    </CloseButtonContainer>
                    <ScheduleHeader>
                        <LogoCalendar src={calendar} />
                        <FlexColumnContainer>
                            <ContentTitle style={{ textAlign: `center` }}>Schedule your Demo Today</ContentTitle>
                            <SmallInfo>Give us a few details to customize your demo with us.</SmallInfo>
                        </FlexColumnContainer>
                    </ScheduleHeader>
                    <ScheduleInputForm
                        action="https://formspree.io/info@intelimina.com"
                        method="POST">
                        <InputLabel>Name (optional)</InputLabel>
                        <InputField name="Name" type="text" />
                        <InputLabel>Email<span className="bg-required">*</span></InputLabel>
                        <InputField name="Email" type="email" placeholder="e.g. sample@email.com" required/>
                        <InputLabel>Contact Number<span className="bg-required">*</span></InputLabel>
                        <InputField name="Contact Number" type="text" placeholder="e.g. 09165555555" required/>
                        <InputLabel>Preferred Date<span className="bg-required">*</span></InputLabel>
                        <DateContainer>
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate.bind(this)}
                                dateFormat="MMMM d, yyyy"
                                className="od-datepicker-date"
                                name="Preferred Date"
                                isClearable
                                style={{
                                    fontSize: `16px`,
                                }}
                            />
                            <DatePicker
                                selected={this.state.startTime}
                                onChange={this.handleChangeTime.bind(this)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                className="od-datepicker-time"
                                name="Preferred Time"
                                isClearable
                                style={{
                                    fontSize: `16px`,
                                }}
                            />
                        </DateContainer>
                        <SubmitRequest
                            style={{ marginBottom: `2rem` }}
                            backgroundColor={props=> props.theme.lightBlue}>
                                Submit Request
                        </SubmitRequest>
                    </ScheduleInputForm>
                </ScheduleForm>
            </ScheduleContainer>
        )
    }
}

export default ScheduleFormContainer;