import React, { Component, Fragment } from 'react';
import { ModuleBox } from './Containers';
import iconsOneSpot from './images/iconsOneSpot.png';
import iconsOnTheGo from './images/iconsOnTheGo.png';
import iconsOnTheDot from './images/iconsOnTheDot.png';


class ModulesContainer extends Component {

    render() {

        return (
            <Fragment>
                <ModuleBox
                    icon={iconsOnTheGo}
                    title={`On The Go`}
                    context={`OnlineDooze works with you, and keeps itself busy so you don’t have to — from bill reminders
                        to renewal notices, rental application to lease payments, facility reservations to complaint resolutions.
                        Your powerful stress-free dose of efficacy in few simple clicks.`}
                    />
                <ModuleBox
                    icon={iconsOnTheDot}
                    title={`On The Dot`}
                    context={`Manage properties and its facilities’ bills, rentals, dues, and payments right when you need
                        them. OnlineDooze provides a real-time and efficient platform for all property stakeholders
                        without worrying about limited office hours, unorganized files, delayed notifications, or missing
                        communications.`}
                    />
                <ModuleBox
                    icon={iconsOneSpot}
                    title={`One-Stop`}
                    context={`Keep track of multiple properties and accounts in a single portal, 24/7. OnlineDooze is the go-to
                        organizer to monitor, maintain, and evaluate property-related transactions and records.
                        Everything in one place, online, anytime.`}
                    />
            </Fragment>
        );
    }
}

export default ModulesContainer;
